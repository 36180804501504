<template>
  <div>
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="p-0 mb-2">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="evento_clienteSelecionado"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vs-row vs-justify="center" v-if="exibirDadosRepresentante">
        <vs-col vs-justify="flex-start" vs-align="center" vs-w="6">  
            <vx-card>
                <vs-row>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="12">   
                        <h6 class="mb-1"> Tipo do Contrato </h6>
                        <v-select                                               
                            :options="CONSTANTE_TIPOS_CONTRATOS" 
                            v-model="tipoContrato"                      
                            class="w-full vs-input--label">
                        </v-select>         
                        <h6 class="mt-3"> Dados do Representante </h6>
                        <label class="vs-input--label">Nome do Representante</label>
                        <vs-input class="mb-4 w-full" v-model="dadosRepresentante.nomeRepresentante" />
                        <label class="vs-input--label">CPF do Representante</label>
                        <vs-input class="mb-4 w-full" v-model="dadosRepresentante.cpfRepresentante" v-mask="'###.###.###-##'" />
                        <label class="vs-input--label">Email de Aceite </label>
                        <vs-input class="mb-4 w-full" v-model="dadosRepresentante.emailAceiteFormularioPedido" />
                    </vs-col>
                </vs-row>   
                <vs-button class="w-full" @click="imprimir" color="success">Gerar Contrato</vs-button>            
            </vx-card>
        </vs-col>
    </vs-row>
        
  </div>
</template>

<script>
import axios from "@/axios.js"
import { CONSTANTE_TIPOS_CONTRATOS } from '@/constantesComboBox.js'
import generico from '@/generico.js'

export default {   
    created() {
      this.CONSTANTE_TIPOS_CONTRATOS = CONSTANTE_TIPOS_CONTRATOS;
    },
    data() {
        return {                        
            listaClientes: [], 
            tipoContrato: null,    
            dadosRepresentante: {nomeRepresentante: null, cpfRepresentante:null, emailAceiteFormularioPedido: null},     
            clienteSelecionado: null,        
            exibirDadosRepresentante: false,   
            errorsValidacao: [],   
            publicPath: process.env.BASE_URL
        }
    },
    methods: {      
        evento_clienteSelecionado() {
            if (this.clienteSelecionado) {
                this.exibirDadosRepresentante= true;    
                this.dadosRepresentante.nomeRepresentante = this.clienteSelecionado.nomeRepresentante;
                this.dadosRepresentante.cpfRepresentante = this.clienteSelecionado.cpfRepresentante;
                this.dadosRepresentante.emailAceiteFormularioPedido = this.clienteSelecionado.emailAceiteFormularioPedido;
            } else {
                this.exibirDadosRepresentante= false;    
            }
        },
        imprimir() {
            let self_ = this;
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja imprimir o contrato do cliente  '
                 + self_.clienteSelecionado.nome + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.imprimirRequest
            });
        },
        imprimirRequest() {
            if (!this.formularioValido()) {
                return;
            }

            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');            
           
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);   
            params.append("nomeRepresentante", this.dadosRepresentante.nomeRepresentante);
            params.append("cpfRepresentante", this.dadosRepresentante.cpfRepresentante);
            params.append("emailAceiteFormularioPedido", this.dadosRepresentante.emailAceiteFormularioPedido);
            params.append("tipoContrato", this.tipoContrato.data);

            axios.post("/ImprimirContrato", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true, responseType: 'arraybuffer' } )
            .then((response) => {
                this.$vs.loading.close();

                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', this.tipoContrato.label + ' - ' + this.clienteSelecionado.CNPJCPF + ' - ' + this.clienteSelecionado.nome + '.docx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();                
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - imprimir - " + error);                 
            })  
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },        
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;                             
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        formularioValido() {
            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];

            if (!this.dadosRepresentante.nomeRepresentante) { this.errorsValidacao.push("Nome do representante obrigatório"); }
            if (!this.dadosRepresentante.cpfRepresentante) { this.errorsValidacao.push("CPF do representante obrigatório"); }
            if (!this.dadosRepresentante.emailAceiteFormularioPedido) { this.errorsValidacao.push("Email de aceite obrigatório"); }
            if (!this.tipoContrato || !this.tipoContrato.data) { this.errorsValidacao.push("Tipo do contrato obrigatório"); }
            if (this.dadosRepresentante.cpfRepresentante && !generico.isCPFValido(this.dadosRepresentante.cpfRepresentante)) {
                this.errorsValidacao.push("CPF do representante inválido");
            }

            if (!this.clienteSelecionado.duracaoContrato) {
                this.errorsValidacao.push("Ajuste a duração do contrato do cliente selecionado.");
            }

            if (!this.errorsValidacao.length) {
                return true;
            }   
        
            return false;
        }
    },
    mounted() {               
        this.fetchListaClientes();
    }
   
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
</style>